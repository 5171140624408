<template>
    <div class="calendar-picker-filter" style="margin-top:4px; display: inline-block">
        <el-button size="small" icon="el-icon-arrow-left"
                   plain style="vertical-align: top; border-radius: 5px 0 0 5px;"
                   @click="goDateChangeBack" type="info"></el-button>
        <el-date-picker size="small" style="border-radius:0;border-left-width: 0;border-right-width: 0 "
                        @change="handleChange"
                        v-model="date_range"
                        type="datetimerange"
                        :picker-options="picker_options"
                        range-separator="-"
                        start-placeholder="开始"
                        format="yyyy-MM-dd HH:mm"
                        :default-time="['00:00:00','23:59:59']"
                        value-format="yyyy-MM-dd HH:mm"
                        end-placeholder="结束">
        </el-date-picker>
        <el-button size="small" icon="el-icon-arrow-right"
                   plain style="vertical-align: top; border-radius: 0 5px 5px 0;"
                   @click="goDateChangeForward" type="info"></el-button>
    </div>
</template>

<script>
export default {
    name: "calendar-picker-filter",
    data() {
        return {
            date_range: [],
            picker_options: {
                'firstDayOfWeek': 1,
                shortcuts: this.shortcuts
            }
        }
    },
    props:{
        value:{ type:Array, default(){ return [
                    ((new Date()).Format('yyyy-MM-dd hh:mm'))
                , ((new Date()).Format('yyyy-MM-dd 23:59'))];
            },
        },
        shortcuts:{ type:Array, default(){ return [{
                text: '当前时间',
                onClick(picker) {
                    const date = new Date();
                    picker.$emit('pick', [date.Format('yyyy-MM-dd hh:mm'), date.Format('yyyy-MM-dd 23:59')]);
                }
            },
                {
                    text: '当日全天',
                    onClick(picker) {
                        const date = new Date();
                        picker.$emit('pick', [date.Format('yyyy-MM-dd 00:00'), date.Format('yyyy-MM-dd 23:59')]);
                    }
                },
                {
                    text: '过去一周',
                    onClick(picker) {
                        const start = new Date();
                        const end = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start.Format('yyyy-MM-dd 00:00'), end.Format('yyyy-MM-dd 23:59')]);
                    }
                },
                {
                    text: '未来一周',
                    onClick(picker) {
                        const start = new Date();
                        const end = new Date();
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start.Format('yyyy-MM-dd 00:00'), end.Format('yyyy-MM-dd 23:59')]);
                    }
                },
                {
                    text: '未来30天',
                    onClick(picker) {
                        const start = new Date();
                        const end = new Date();
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start.Format('yyyy-MM-dd 00:00'), end.Format('yyyy-MM-dd 23:59')]);
                    }
                },
                {
                    text: '未来一年',
                    onClick(picker) {
                        const start = new Date();
                        const end = new Date();
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 365);
                        picker.$emit('pick', [start.Format('yyyy-MM-dd 00:00'), end.Format('yyyy-MM-dd 23:59')]);
                    }
                }
            ]}
        },
    },
    watch:{
      value:{
          handler(val){
              this.date_range = val;
          },
          immediate:true,
      }
    },
    methods: {
        handleChange() {
            this.$emit('change',this.date_range);
        },
        goDateChangeBack() {
            const valid = this.date_range && this.date_range.length === 2;
            let first = valid ? this.$dateParse(this.date_range[0].substr(0,10)) : new Date();
            let second = valid ? this.$dateParse(this.date_range[1].substr(0,10)) : new Date();

            first.setDate(first.getDate() - 1);
            second.setDate(second.getDate() - 1);
            this.date_range = [first.Format('yyyy-MM-dd 00:00'), second.Format('yyyy-MM-dd 23:59')];
            this.date = first.Format('yyyy-MM-dd');
            this.handleChange()
        },
        goDateChangeForward() {
            const valid = this.date_range && this.date_range.length === 2;
            let first = valid ? this.$dateParse(this.date_range[0].substr(0,10)) : new Date();
            let second = valid ? this.$dateParse(this.date_range[1].substr(0,10)) : new Date();

            first.setDate(first.getDate() + 1);
            second.setDate(second.getDate() + 1);
            this.date_range = [first.Format('yyyy-MM-dd 00:00'), second.Format('yyyy-MM-dd 23:59')];
            this.date = first.Format('yyyy-MM-dd');
            this.handleChange()
        },
    }
}
</script>

<style >
.calendar-picker-filter .el-date-editor--datetimerange.el-input, .calendar-picker-filter .el-date-editor--datetimerange.el-input__inner {
    width: 320px;
}
</style>
