export default {
    created() {
        this.model = 'Admin.' + this.$route.meta.model;
    },
    computed: {
        can_copy() {
            return this.$route.meta ? this.$route.meta.copy : false;
        },
        can_add() {
            return this.$route.meta ? !this.$route.meta.no_add : true
        },
        can_delete() {
            return this.$route.meta ? !this.$route.meta.no_delete : true
        },
        can_edit() {
            return this.$route.meta ? !this.$route.meta.no_edit : true
        }
    },
    methods:{
        handleEdit(id) {
            this.$router.push(`${this.path}/edit/${id}`);
        },
        handleCopy(id) {
            this.$router.push(`${this.path}/copy/${id}`);
        },
        afterReload() {
            if (this.search.extra && this.search.extra.C) {
                this.$set(this.search.extra,'C' , this.user.company);
            }
        },
        beforeReload(){
            if (this.search.extra && this.search.extra.C) {
                this.user.company = this.search.extra.C;
            }
        }

    },
    watch:{
        '$route.path':function (newVal) {
            if (newVal === "/admin/program") {
                this.afterReload();
                this.reload();
            }
        }
    },

}
