<template>
    <el-row style="padding-right: 20px;">
        <el-col :span="(dual && (!item.row))?12:24" v-for="(item,field) in fields" :key="field">
            <front-single-form-editor :single="single" :field="field" :item="item">
            </front-single-form-editor>
        </el-col>
    </el-row>
</template>

<script>

    import FrontSingleFormEditor from "@/components/front-single-form-editor";
    export default {
        name: "front-single-form",
        components: {FrontSingleFormEditor},
        data() {
            return {
                annotation:{}
            }
        },
        props: {
            single: {
                type: Object,
                default() {
                    return {}
                }
            },
            fields: {
                type: Object,
                default() {
                    return {}
                }
            },
            dual: {
                type: [Boolean, Number],
                default() {
                    return false
                },
            }
        },

    }
</script>

<style scoped lang="scss">

</style>
