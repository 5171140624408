<template>
    <el-dialog :visible.sync="dialogFormVisible" :append-to-body="mobile_mode"
               :close-on-click-modal="false" :before-close="handleClose">

        <div slot="title"><b class="text-info">{{single.subject}}</b></div>
        <div class="">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="d-flex dd-block">
                        <div class="dd-label">时间:</div>
                        <div class="dd-content">{{single.created_at}}</div>
                    </div>
                </el-col>
                <el-col :span="24">
                    <div class="d-flex dd-block">
                        <div class="dd-label">管理员:</div>
                        <div class="dd-content">{{single.visitor_name}}</div>
                    </div>
                </el-col>
                <el-col :span="24">
                    <div class="d-flex dd-block">
                        <div class="dd-label">分类:</div>
                        <div class="dd-content">{{single.category}}</div>
                    </div>
                </el-col>
                <el-col :span="24">
                    <div class="d-flex dd-block">
                        <div class="dd-label">数据:</div>
                        <pre class="dd-content" style="line-height: 20px;">{{single.text}}</pre>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="$emit('cancel')">关 闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: "log-view",
        data() {
            return {
                dialogFormVisible: false,
                approve_form: {approve_result: '2'},
                qrcode: null,
            }
        },
        components: {},
        props: {
            view_mode: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            single: {
                type: Object,
                default() {
                    return {};
                }
            },
            mobile_mode:{
                type: Boolean,
                default() {
                    return false;
                }
            }
        },
        watch: {
            view_mode: {
                handler(newVal) {
                    this.dialogFormVisible = newVal;
                }
            },
        },
        methods: {
            handleClose(done) {
                done();
                this.$emit('cancel');
            },
        }
    }
</script>

<style lang="scss" scoped>

    .dd-block {
        border-bottom: solid 1px #b2ebf2;
        .dd-label {
            width: 80px;
            text-align: left;
            font-weight: bold;
            display: inline-block;
            line-height: 30px;
            background-color: aliceblue;
            color: #0097A7;
            padding-left: 5px;
        }

        .dd-content {
            flex: 1;
            text-align: left;
            margin-left: 10px;
            display: inline-block;
            line-height: 30px;
            overflow: auto;
        }
    }

</style>
