export default {
    name: 'admin-admin',
    created() {
        this.model = 'Admin.' + this.$route.meta.model;
    },
    computed: {
        can_copy() {
            return this.$route.meta ? this.$route.meta.copy : false;
        },
        can_add() {
            return this.$route.meta ? !this.$route.meta.no_add : true
        },
        can_delete() {
            return this.$route.meta ? !this.$route.meta.no_delete : true
        },
        can_edit() {
            return this.$route.meta ? !this.$route.meta.no_edit : true
        },
        can_preview() {
            return this.$route.meta ? this.$route.meta.preview : false
        },
    }
}
