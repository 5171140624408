<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :front_extra="extra_filters"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"
                     :btn_copy = "can_copy"
                     @copy="handleCopy"
                     :btn_add = "can_add"
                     :btn_delete="can_delete"
                     :btn_edit="can_edit"
        >
            <el-button slot="footer" type="warning" plain icon="el-icon-download" @click="downloadReport">下载报告</el-button>

        </front-table>
        <!--编辑界面-->
        <edit-dialog :edit_mode="edit_mode" :model="model" :single="single" :fields="fields" dual
                     @success="handleSuccess" @cancel="handleCancel"></edit-dialog>

        <div class="clearfix"></div>

    </section>
</template>

<script>
    import EditDialog from "../components/edit-dialog";
    import FrontTable from "../components/front-table";
    import FrontMixins from "../common/mixins/front-mixins";
    import AdminAdmin from "./mixins/admin-admin-mixin"

    export default {
        mixins: [FrontMixins,AdminAdmin],
        components: {FrontTable, EditDialog},
        methods:{
            downloadReport(){
                this.handleDownloadReport("Admin.Visitor.Report",{},'用户清单');
            }
        }
    }

</script>

<style lang="scss">

</style>
