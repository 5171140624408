<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :front_extra="extra_filters"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"
                     :btn_copy = "can_copy"
                     @copy="handleCopy"
                     :btn_add = "can_add"
                     :btn_delete="can_delete"
                     :btn_edit="can_edit"
                     @review="handleReview"
                     :btn_review="true"
        >
        </front-table>
        <!--编辑界面-->

        <program-view :single="single" :view_mode="view_mode" @cancel="view_mode = false"></program-view>

    </section>
</template>

<script>
    import FrontTable from "../components/front-table";
    import FrontMixins from "../common/mixins/front-mixins";
    import AdminEdit from "./mixins/admin-edit-mixin"
    import ProgramView from "@/components/program-view";

    export default {
        name: 'admin-program',
        mixins: [FrontMixins,AdminEdit],
        components: {ProgramView, FrontTable},
        data(){
            return {
                path:"/admin/program",
            }
        },
    }

</script>

<style lang="scss">

</style>
