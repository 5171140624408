<template>
    <div class="webhome-wrapper">
        <el-row class="home-header justify-content-between d-flex">
            <el-col class="header-logo d-flex ">
                <div class="logo" :style="{flex:collapsed?'0 0 64px':'0 0 229px'}" style="cursor: pointer" @click="justToPortal" >
                    <img :src="applogo" v-if="!collapsed" />
                </div>
                <div class="switch" @click.prevent="collapse">
                    <i :class="collapsed?'el-icon-s-unfold':'el-icon-s-fold'"></i>
                </div>
            </el-col>
            <el-col class="userinfo d-flex flex-row-reverse">
                <div class="locale" @click.prevent="setLocale">
                    <div style="line-height:50px;">{{ en ? 'EN' : '中' }}</div>
                </div>
                <el-dropdown @command="handleCommand" style="padding-right: 10px;">
                    <div style="cursor: pointer">
                        <div class="userinfo-image"></div>
                        <span class="userinfo-name">{{ $t("TITLE.GREETING") }},{{ user.name }}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item icon="el-icon-table-lamp" command="logout">{{ $t("TITLE.SIGN-OUT") }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-row>
        <el-row class="main">
            <aside :class="collapsed?'menu-collapsed':'menu-expanded'" style="overflow-y: auto">
                <!--导航菜单-->
                <el-menu
                    :default-active="$route.path"
                    class="el-menu-vertical-dark"
                    :collapse="collapsed"
                    unique-opened router
                >
                    <template v-for="(item) in menu">
                        <el-submenu :index="item.name" v-if="item.children" :key="item.name">
                            <template #title><i :class="item.icon"></i>
                                <span slot="title">{{ item.name }}</span>
                            </template>
                            <el-menu-item v-for="child in item.children" :index="child.path" :key="child.name">
                                <i v-if="child.icon" :class="child.icon"></i><span slot="title">{{ child.name }}</span>
                            </el-menu-item>
                        </el-submenu>
                        <el-menu-item v-if="!item.children" :index="item.path" :key="item.name">
                            <i :class="item.icon"></i>
                            <span slot="title">{{ item.name }}</span>
                        </el-menu-item>
                    </template>
                </el-menu>
            </aside>
            <section class="content-container">
                <div class="grid-content bg-purple-light">
                    <el-col :span="24" class="breadcrumb-container">
                        <div class="d-flex justify-content-between">
                            <strong class="title"><i class="el-icon-back" style="cursor:pointer" @click="goBack"></i>
                                {{ $route.name }}</strong>
                        </div>
                    </el-col>
                    <el-col :span="24" class="content-wrapper">
                        <keep-alive>
                            <router-view :key="pageKey" v-if="$route.meta && $route.meta.keepAlive"></router-view>
                        </keep-alive>
                        <router-view :key="pageKey" v-if="!($route.meta && $route.meta.keepAlive)"></router-view>
                    </el-col>
                </div>
            </section>
        </el-row>
    </div>
</template>

<script>

import Home from "@/router/home";
import CommonMixin from "@/common/mixins/common-mixin";
import "@/views/scss/web-home.scss";

export default {
    components: {},
    mixins: [CommonMixin],
    data() {
        return {
            collapsed: false,
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: '',
            },
        }
    },
    computed: {
        applogo() {
            return '/assets/applogo.png';
        },
        menu() {
            let menu = [];
            const vm = this;
            const level = parseInt(this.user.role_level);
            Home.forEach(function (item) {
                let can = true;
                if (item.meta) {
                    if (item.meta.level) { //判断级别够不够
                        can = item.meta.level <= level;
                        if (!can && item.meta.position) {
                            can = (vm.user.position == item.meta.position);
                        }
                    }
                    if (item.meta.mobile) {
                        can = false;
                    }
                }
                if (!item.hidden && can) {
                    const children = item.children;
                    if (children.length > 1) {  // 多个子项
                        let sub_menu = [];
                        children.forEach(function (child) {
                            let can = true;
                            if (child.meta && child.meta.level) { //子项也有权限
                                can = child.meta.level <= level;
                            }
                            if (child.meta && child.meta.mobile) { // 仅仅在手机端显示
                                can = false;
                            }
                            if (can && !(child.meta && child.meta.hidden)) { //子项隐藏
                                sub_menu.push(child);
                            }
                        });
                        menu.push({icon: item.icon, name: item.name, children: sub_menu});

                    } else if (children.length === 1) { //单个子目录
                        const child = children[0];
                        menu.push(Object.assign({icon: item.icon, children: false}, child))
                    }
                }
            });
            return menu;
        },
        pageKey() {
            if (this.$route.meta) {
                if (this.$route.meta.model) return this.$route.meta.model;
                else return this.$route.name;
            }
            return '';
        }
    },
    methods: {
        handleCommand(command) {
            if (command === 'logout') this.logout();
            else if (command === 'profile') this.$router.push("/profile");
            else if (command === 'password') this.$router.push("/password");
        },
        logout: function () { //退出登录
            this.$confirm('&nbsp;&nbsp;将要退出系统, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true

            }).then(() => {
                this.clear();
                const return_url = this.$organization.signin_url;
                if (this.$organization.sso_out) {
                    window.location.href = this.$organization.sso_out + encodeURIComponent(return_url); // cas 跳转
                } else {
                    this.$router.replace({name: 'sign-in'});
                }
            }).catch(() => {
            });
        },
        //折叠导航栏
        collapse() {
            this.collapsed = !this.collapsed;
            this.setCollapsed(this.collapsed);
        },
        goBack() {
            this.$router.back();
        },
        setLocale() {
            this.setLanguage(!this.english);
        },
        justToPortal() {
            this.$router.push('/portal');
        }
    },
    created() {
        this.collapsed = this.isCollapsed;
        this.setWindowTitle();
        if (this.$isMobile()) {
            this.$router.push({path: '/portal'});
        }
    },
}

</script>

